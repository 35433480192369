<template>
  <div>
    <model title="联系作者">
      <lh-img width="300px" :src="require('../../static/wx.jpg')"/>
    </model>
  </div>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  components: { Model },
  name:"ContactAuthor"
}
</script>

<style lang="less" scoped>
p{
  text-indent:2em;
}
</style>